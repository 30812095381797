import type { PropType, VNode } from 'vue';
import './Badge.css';

export default defineComponent({
  name: 'Badge',
  props: {
    size: {
      default: 'normal',
      type: String as PropType<'mini' | 'normal'>,
    },
    type: {
      default: 'neutral',
      type: String as PropType<
        'caution' | 'neutral' | 'warning' | 'success' | 'loyalty'
      >,
    },
  },
  render(): VNode | null {
    if (!this.$slots.default || (this.size === 'mini' && !this.$slots.icon))
      return null;
    return (
      <div class="badge" data-size={this.size} data-type={this.type}>
        {this.$slots.icon?.()}
        <div class="content">{this.$slots.default()}</div>
      </div>
    );
  },
});
